import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  DEFAULT_SUPPORT_STEPS,
  FEATURES,
  SUPPORT_STEP_ASSIGNEE_TYPES,
  SUPPORT_STEP_TYPES,
  SUPPORT_STEP_KEYWORDS,
} from 'src/app/constants';
import { CollaboratorType } from 'src/models';
import { ApiService } from 'src/services/api.service';
import { AuthService } from 'src/services/auth.service';
import { CollaboratorTypesService } from 'src/services/collaborator-types.service';
import { SearchablePopoverService } from 'src/services/searchable-popover.service';
import { SlackService } from 'src/services/slack.service';
import { UserTeamsService } from 'src/services/user-teams.service';
import { UsersService } from 'src/services/users.service';

import { addConditionalRule, supportsConditionalRules } from './common';

@Component({
  selector: 'app-support-steps',
  templateUrl: './support-steps.component.html',
  styleUrls: ['./support-steps.component.scss'],
})
export class SupportStepsComponent implements OnInit {
  @Input() initialSupportSteps: any[] = [];
  @Input() isDefaults = false;
  @Input() disabled = false;
  @Input() createdBy;

  @Output() didChange = new EventEmitter<any[]>();

  isLoading = true;
  channels: any[];
  assigneeLookups: any = {
    [SUPPORT_STEP_KEYWORDS.CREATED_BY_USER]: {
      id: SUPPORT_STEP_KEYWORDS.CREATED_BY_USER,
      displayName: 'User who created customer',
    },
    [SUPPORT_STEP_KEYWORDS.PRIMARY_SUPPORT_ASSIGNEE]: {
      id: SUPPORT_STEP_KEYWORDS.PRIMARY_SUPPORT_ASSIGNEE,
      displayName: 'Primary support rep',
    },
    [SUPPORT_STEP_KEYWORDS.SECONDARY_SUPPORT_ASSIGNEE]: {
      id: SUPPORT_STEP_KEYWORDS.SECONDARY_SUPPORT_ASSIGNEE,
      displayName: 'Secondary support rep',
    },
    [SUPPORT_STEP_KEYWORDS.ASSIGNEE_MANAGER]: {
      id: SUPPORT_STEP_KEYWORDS.ASSIGNEE_MANAGER,
      displayName: "Assignee's manager",
    },
    [SUPPORT_STEP_KEYWORDS.SUBMITTER_MANAGER]: {
      id: SUPPORT_STEP_KEYWORDS.SUBMITTER_MANAGER,
      displayName: "Submitter's manager",
    },
  };
  supportSteps: any[] = [];
  isMultiStepDisabled = false;
  lookups: Record<string, Record<string, string>> = {};
  hasManageChannelsScope = false;
  collaboratorTypes: Partial<CollaboratorType>[] = [];
  isSlackDisconnected = false;

  SUPPORT_STEP_KEYWORDS = SUPPORT_STEP_KEYWORDS;

  constructor(
    public authService: AuthService,
    private apiService: ApiService,
    private slackService: SlackService,
    private usersService: UsersService,
    private userTeamsService: UserTeamsService,
    private searchablePopoverService: SearchablePopoverService,
    private collaboratorTypesService: CollaboratorTypesService,
  ) {}

  async ngOnInit() {
    this.isLoading = true;

    await Promise.all([
      this.loadSupportUsers(),
      this.loadSupportTeams(),
      this.loadConnectedChannels(),
      this.loadCollaboratorTypes(),
    ]);

    this.hasManageChannelsScope = await this.slackService.hasScope('channels:manage');

    this.supportSteps = (
      this.initialSupportSteps?.length
        ? this.initialSupportSteps.map((s) => {
            if (
              [SUPPORT_STEP_TYPES.ASSIGNMENT, SUPPORT_STEP_TYPES.ESCALATION].includes(s.type) &&
              s.userId != null &&
              s.assigneeId == null
            ) {
              s.assigneeId = s.userId;
              s.assigneeType = SUPPORT_STEP_ASSIGNEE_TYPES.USER;
            }

            return s;
          })
        : DEFAULT_SUPPORT_STEPS
    ).map((s) => {
      if (!this.isDefaults && this.createdBy && s.assigneeId === SUPPORT_STEP_KEYWORDS.CREATED_BY_USER) {
        s.assigneeId = this.createdBy;
      }

      return s;
    });

    await this.loadLookups();

    if (!this.initialSupportSteps?.length) {
      this.didChange.emit(this.supportSteps);
    }

    this.isLoading = false;
    this.isMultiStepDisabled = this.authService.restrictedFeatures.includes(FEATURES.MULTISTEP_ASSIGNMENT_RULES);
  }

  async loadSupportUsers() {
    const supportUsers = await this.usersService.list({
      limit: null,
      order: ['name'],
      descending: false,
      where: [
        {
          field: 'name',
          operator: '!=',
          value: null,
        },
        {
          field: 'email',
          operator: '!=',
          value: null,
        },
      ],
    });
    supportUsers.data.forEach((u) => {
      this.assigneeLookups[u.id] = {
        id: u.id,
        displayName: `${u.name}${u.email ? ` (${u.email})` : ''}`,
        name: u.name,
        email: u.email,
        slackId: u.slackId,
        type: SUPPORT_STEP_ASSIGNEE_TYPES.USER,
      };
    });
  }

  async loadSupportTeams() {
    const supportTeams = await this.userTeamsService.list({
      limit: null,
      order: ['name'],
      descending: false,
      where: [
        {
          field: 'name',
          operator: '!=',
          value: null,
        },
        {
          field: 'slackId',
          operator: '!=',
          value: null,
        },
      ],
    });
    supportTeams.data.forEach((u) => {
      this.assigneeLookups[u.id] = {
        id: u.id,
        displayName: `${u.name} (Team)`,
        name: u.name,
        slackId: u.slackId,
        type: SUPPORT_STEP_ASSIGNEE_TYPES.TEAM,
      };
    });
  }

  async loadCollaboratorTypes() {
    const collaboratorTypes = await this.collaboratorTypesService.list({
      order: ['name'],
    });

    this.collaboratorTypes = collaboratorTypes.data;
  }

  async loadLookups() {
    const lookupIds: Record<string, string[]> = {};

    for (const supportStep of this.supportSteps) {
      if (supportStep.branches?.length) {
        for (const branch of supportStep.branches) {
          if (branch.conditions?.length) {
            for (const condition of branch.conditions) {
              if (!lookupIds[condition.field]) {
                lookupIds[condition.field] = [];
              }

              lookupIds[condition.field].push(condition.value);
            }
          }
        }
      }
    }

    if (Object.keys(lookupIds).length) {
      this.lookups = await this.apiService.postPromise('/saved-filters/get-lookup-values', lookupIds);
    }
  }

  async loadConnectedChannels() {
    try {
      this.channels = await this.slackService.getConnectedChannels();
    } catch (e) {
      if (e.status === 500) {
        this.isSlackDisconnected = true;
      }
    }
  }

  removeSupportStep(i: number) {
    this.supportSteps.splice(i, 1);

    this.didChange.emit(this.supportSteps);
  }

  addSupportStep() {
    if (this.supportSteps?.length) {
      this.supportSteps.push({
        type: 'reminder',
        minutes: 60,
      });
    } else {
      this.supportSteps.push({
        type: 'assignment',
        assigneeId: null,
        assigneeType: null,
      });
    }

    this.didChange.emit(this.supportSteps);
  }

  onReorder({ detail }) {
    const { from, to } = detail;

    this.supportSteps.splice(to + 1, 0, this.supportSteps.splice(from + 1, 1)[0]);

    detail.complete();

    this.didChange.emit(this.supportSteps);
  }

  showAssigneeOptions(supportStep: any) {
    this.searchablePopoverService.assignees({
      event,
      selectedId: supportStep.assigneeId,
      showCreatedByUserOption: true,
      showPrimaryRepOption: true,
      showNoneOption: true,
      callback: async (id: string, _name: string, object: any) => {
        supportStep.assigneeId = id;
        supportStep.assigneeType = object?.type ?? SUPPORT_STEP_ASSIGNEE_TYPES.USER;
      },
    });
  }

  channelCompareFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === null);
  }

  createChannelCompareFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === false);
  }

  remainOnCurrentSteptIfNoAssigneeFoundFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === false);
  }

  createChannelIsPrivateCompareFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === true);
  }

  addConditionalRule(i: number) {
    return addConditionalRule(this.supportSteps[i]);
  }

  supportsConditionalRules(i: number) {
    return supportsConditionalRules(this.supportSteps[i]);
  }
}
